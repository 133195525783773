import React from 'react';
import Aplication from "./Aplication";
import Login from "./login/Login";
import {isAuth} from "./Auth/Auth";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom"

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        isAuth() ? (
           <Component {...props} />
        ) : (
            <Redirect to={{pathname: "/", state: {from: props.location}}}/>
        )
    )} />
);

class App extends React.Component {
    render() {
        return <><BrowserRouter>
            <Switch>
                <Route path="/" component={Login}  exact/>
                <PrivateRoute path="" component={Aplication} />
            </Switch>

        </BrowserRouter> </>
    }
}

export default App;
