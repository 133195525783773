import React from 'react';
import {Redirect} from "react-router-dom";

class SideBarFooter extends React.Component {

    constructor(props) {
        super(props);
    }

    logout = () => {
        sessionStorage.removeItem('token');
        return <Redirect to="/" />
    }

    render() {
        return (
            <div className="side-bar-footer">
                <ul>
                    <li><a href="#">Bills</a></li>
                    <li><a href="#" onClick={this.logout}>Logout</a></li>
                </ul>
            </div>
        );
    }
}

export default SideBarFooter;
