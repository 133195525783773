import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class LogsByStep extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.info !== this.props.info) {
            this.internalChart.series[0].setData(this.props.info);
        }
    }

    afterChartCreated = (chart) => {
        this.internalChart = chart;
    }

    static getConfig = () => ({
        chart: {
            type: 'column',
            animation: Highcharts.svg,
        },
        title: {
            text: null
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: false
        },
        legend: {enabled: false},

        series: [{
            data: []
        }]
    })

    render() {
        return (
            <div className="info-exec-box-column-row">
                <h2 className="box-extractor-name">Logs by Step</h2>
                <div className="chart-exec-box">
                    <HighchartsReact
                        containerProps={{style: {height: "100%"}}}
                        highcharts={Highcharts}
                        options={LogsByStep.getConfig()}
                        callback={this.afterChartCreated}
                    />
                </div>
            </div>
        );
    }
}

export default LogsByStep;