import React from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import CardChart from "../highchart/CardChart";
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})


class CardBot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            info_last_exec: "info-ok", status_last_exec: "Completed", loading: true, num_execs: 0, error: 0,
            logs: 0, data: 0
        }
        this._isMounted = false;
    }


    // Function to call list of bots
    getExecInfo = async () => {
        // Build url
        const url = '/execs/resume/' + this.props.id;
        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {data = false})

        if (data) {
            // Pushing fetched data in card info
            if(this._isMounted) {
                this.setState({
                    info_last_exec: data.last_exec_info, status_last_exec: data.last_exec_status,
                    loading: false, num_execs: data.num_execs, error: data.error, logs: data.logs, data: data.data
                })
            }
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this.getExecInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const bot_name = this.props.bot_name;
        const status = this.props.status ? "On" : "Off";
        const debug = this.props.debug ? "On" : "Off";
        const info_last_exec = this.state.info_last_exec;
        const status_last_exec = this.state.status_last_exec
        const options_chart = {
            chart: {
                type: 'column'
            },
            title: {
                text: null
            },
            yAxis: {
                title: false
            },
            xAxis: {
                type: 'category'
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    data: [
                        {
                            name: "error",
                            y: this.state.error,
                            color: '#FCD9E0'
                        },
                        {
                            name: "log",
                            y: this.state.logs,
                            color: '#FFF4CE'
                        },
                        {
                            name: "data",
                            y: this.state.data,
                            color: '#DDF1DE'
                        }
                    ]
                }
            ]
        }

        let content;
        if (this.state.loading) {
            content = <div className="overlay-card-bot">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"></i><span
                    className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>;
        } else if (this.state.num_execs === 0) {
            content = <div className="overlay-card-bot-no-data"><i className="fas fa-robot no-data"></i><span
                className="span-no-data">No data</span></div>;
        } else {
            content = <CardChart options={options_chart}/>;
        }

        return (
                <div className="exec-box">
                    <Link to={ { pathname:`/dashboard/bot/${this.props.id}`, name: bot_name}} ><h2 className="box-extractor-name">{bot_name}</h2></Link>
                    <div className="status-dash-view-exec">
                        <label>Status: <span>{status}</span></label>
                        <label className="status-dash-view-exec-label">Debug: <span>{debug}</span></label>
                    </div>
                    <div className="status-dash-view-exec">
                        <label>Last exec: <span>{status_last_exec}</span></label>
                        <span className={info_last_exec}><span>Ok</span></span>
                    </div>
                    <div className="chart-exec-box">{content}</div>
                </div>
        );
    }
}

export default CardBot;
