import React from 'react';
import axios from "axios";
import ListSched from "./ListSched";
import DatePicker from "react-datepicker";
import {getUrlToken} from "../../Auth/Auth";

import "react-datepicker/dist/react-datepicker.css";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class Schedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(), minute: 0, hour: 0, utc: "America/Sao_Paulo", seed: "",
            bot_id: this.props.match.params.id, saving: false, switchList: false, alert_seed: "alert-seed-hidden",
            css_alert_schedule: "alert-schedule-hidden", alert_schedule: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleChangeMinute = this.handleChangeMinute.bind(this);
        this.handleChangeHour = this.handleChangeHour.bind(this);
        this.handleChangeUtc = this.handleChangeUtc.bind(this);
        this.handleChangeSeed = this.handleChangeSeed.bind(this);
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    saveScheds = async (sched_data) => {
        this.setState({saving: true})
        const url = '/add-schedule'
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        await api.post(url, sched_data, config).then(res => {

            if(this._isMounted) {
                this.setState({
                    saving: false, switchList: !this.state.switchList,
                    css_alert_schedule: "alert-schedule-hidden"
                });
            }
        }).catch(res => {
            if(this._isMounted) {
                this.setState({
                    saving: false,
                    css_alert_schedule: "alert-schedule", alert_schedule: res.response.data.message
                });
            }
        })
    }

    handleChange(date) {
        this.setState({
            startDate: date
        })
    }

    handleChangeMinute(e) {
        e.preventDefault();
        this.setState({
            minute: e.target.value
        })
    }

    handleChangeHour(e) {
        e.preventDefault();
        this.setState({
            hour: e.target.value
        })
    }

    handleChangeUtc(e) {
        e.preventDefault();
        this.setState({
            utc: e.target.value
        })
    }

    handleChangeSeed(e) {
        e.preventDefault();
        this.setState({
            seed: e.target.value
        })
    }

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    isEmptyValue = (value) => {
        if (value === '' || value === null || value === undefined) {
            return true
        } else {
            return false
        }
    }

    onFormSubmit(e) {
        e.preventDefault();
        if (this.isEmptyValue(this.state.seed)) {
            this.setState({alert_seed: "alert-seed-hidden"})
            let sched_data = {
                bot_fk: this.state.bot_id,
                year: this.state.startDate.getFullYear(),
                month: this.state.startDate.getMonth() + 1,
                day: this.state.startDate.getDate(),
                minute: parseInt(this.state.minute),
                hour: parseInt(this.state.hour),
                utc: this.state.utc
            }
            this.saveScheds(sched_data);
        } else {
            if (this.IsJsonString(this.state.seed)) {
                this.setState({
                    alert_seed: "alert-seed-hidden"
                })
                let sched_data = {
                    bot_fk: this.state.bot_id,
                    year: this.state.startDate.getFullYear(),
                    month: this.state.startDate.getMonth() + 1,
                    day: this.state.startDate.getDate(),
                    minute: parseInt(this.state.minute),
                    hour: parseInt(this.state.hour),
                    utc: this.state.utc,
                    seed: this.state.seed
                }
                this.saveScheds(sched_data);
            } else {
                this.setState({
                    alert_seed: "alert-seed"
                })
            }
        }
    }

    render() {
        const {id} = this.props.match.params;
        let overlay_saving;
        if (this.state.saving) {
            overlay_saving = <div className="overlay-schedule-add">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"/><span
                    className="span-loading wait-loading">Saving <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>
        }

        return (
            <div id="schedules">
                <div id="container-schedule">
                    <div className="name-actions-collector">
                        <div id="name-schedule-collector">Linkedin collector</div>
                        <div className="actions-schedule-collector">
                            <button onClick={this.onFormSubmit} className="btn-add">Save</button>
                        </div>
                    </div>

                    <div className="info-schedule">
                        <div className="info-schedule-by-date">
                            {overlay_saving}
                            <h5>Schedule by date <span
                                className={this.state.css_alert_schedule}>{this.state.alert_schedule}</span></h5>
                            <div className="container-schedule-inputs">
                                <div className="container-schedule-inputs-left">
                                    <div className="container-schedule-inputs-left-input">
                                        <div className="inputs-schedule">
                                            <label>UTC:</label>
                                            <select className="flat-theme" onChange={this.handleChangeUtc}
                                                    value={this.state.utc}>
                                                <option label="UTC-SP" value="America/Sao_Paulo">UTC-SP
                                                </option>
                                                <option label="UTC-NY" value="America/New_York">UTC-NY</option>
                                                <option label="UTC-0" value="GMT-0">UTC-0</option>
                                            </select>
                                        </div>

                                        <div className="inputs-schedule">
                                            <label>Date:</label>
                                            <div className="input-append">
                                                <DatePicker
                                                    selected={this.state.startDate}
                                                    onChange={this.handleChange}
                                                    name="startDate"
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                                <span className="add-on iconed glyphicons calendar">
                                                        <i className="far fa-calendar-alt"/>
                                                    </span>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="container-schedule-inputs-left-input">
                                        <div className="inputs-schedule">
                                            <label>Hour:</label>
                                            <select className="flat-theme" onChange={this.handleChangeHour}
                                                    value={this.state.hour}>
                                                <option label="0" value="0">0</option>
                                                <option label="1" value="1">1</option>
                                                <option label="2" value="2">2</option>
                                                <option label="3" value="3">3</option>
                                                <option label="4" value="4">4</option>
                                                <option label="5" value="5">5</option>
                                                <option label="6" value="6">6</option>
                                                <option label="7" value="7">7</option>
                                                <option label="8" value="8">8</option>
                                                <option label="9" value="9">9</option>
                                                <option label="10" value="10">10</option>
                                                <option label="11" value="11">11</option>
                                                <option label="12" value="12">12</option>
                                                <option label="13" value="13">13</option>
                                                <option label="14" value="14">14</option>
                                                <option label="15" value="15">15</option>
                                                <option label="16" value="16">16</option>
                                                <option label="17" value="17">17</option>
                                                <option label="18" value="18">18</option>
                                                <option label="19" value="19">19</option>
                                                <option label="20" value="20">20</option>
                                                <option label="21" value="21">21</option>
                                                <option label="22" value="22">22</option>
                                                <option label="23" value="23">23</option>
                                            </select>
                                        </div>
                                        <div className="inputs-schedule">
                                            <label>Minute:</label>
                                            <select className="flat-theme" onChange={this.handleChangeMinute}
                                                    value={this.state.minute}>
                                                <option label="0" value="0">0</option>
                                                <option label="1" value="1">1</option>
                                                <option label="2" value="2">2</option>
                                                <option label="3" value="3">3</option>
                                                <option label="4" value="4">4</option>
                                                <option label="5" value="5">5</option>
                                                <option label="6" value="6">6</option>
                                                <option label="7" value="7">7</option>
                                                <option label="8" value="8">8</option>
                                                <option label="9" value="9">9</option>
                                                <option label="10" value="10">10</option>
                                                <option label="11" value="11">11</option>
                                                <option label="12" value="12">12</option>
                                                <option label="13" value="13">13</option>
                                                <option label="14" value="14">14</option>
                                                <option label="15" value="15">15</option>
                                                <option label="16" value="16">16</option>
                                                <option label="17" value="17">17</option>
                                                <option label="18" value="18">18</option>
                                                <option label="19" value="19">19</option>
                                                <option label="20" value="20">20</option>
                                                <option label="21" value="21">21</option>
                                                <option label="22" value="22">22</option>
                                                <option label="23" value="23">23</option>
                                                <option label="24" value="24">24</option>
                                                <option label="25" value="25">25</option>
                                                <option label="26" value="26">26</option>
                                                <option label="27" value="27">27</option>
                                                <option label="28" value="28">28</option>
                                                <option label="29" value="29">29</option>
                                                <option label="30" value="30">30</option>
                                                <option label="31" value="31">31</option>
                                                <option label="32" value="32">32</option>
                                                <option label="33" value="33">33</option>
                                                <option label="34" value="34">34</option>
                                                <option label="35" value="35">35</option>
                                                <option label="36" value="36">36</option>
                                                <option label="37" value="37">37</option>
                                                <option label="38" value="38">38</option>
                                                <option label="39" value="39">39</option>
                                                <option label="40" value="40">40</option>
                                                <option label="41" value="41">41</option>
                                                <option label="42" value="42">42</option>
                                                <option label="43" value="43">43</option>
                                                <option label="44" value="44">44</option>
                                                <option label="45" value="45">45</option>
                                                <option label="46" value="46">46</option>
                                                <option label="47" value="47">47</option>
                                                <option label="48" value="48">48</option>
                                                <option label="49" value="49">49</option>
                                                <option label="50" value="50">50</option>
                                                <option label="51" value="51">51</option>
                                                <option label="52" value="52">52</option>
                                                <option label="53" value="53">53</option>
                                                <option label="54" value="54">54</option>
                                                <option label="55" value="55">55</option>
                                                <option label="56" value="56">56</option>
                                                <option label="57" value="57">57</option>
                                                <option label="58" value="58">58</option>
                                                <option label="59" value="59">59</option>
                                            </select>
                                        </div>


                                    </div>
                                </div>
                                <div className="container-schedule-inputs-right">
                                    <fieldset className="fieldset-seed">
                                        <label htmlFor="seed">Seed <span
                                            className={this.state.alert_seed}> (wrong json seed)</span></label>
                                        <textarea className="textarea-seed" type="text" id="observation" rows="5"
                                                  maxLength="500" onChange={this.handleChangeSeed}
                                                  value={this.state.seed}>
                            </textarea>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="info-schedule-by-rate">
                            <div>
                                <h5>Schedule by rate</h5>
                                <label className="switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"/>
                                </label>
                            </div>
                            <div className="container-schedule-rate-inputs">
                                <div className="container-schedule-inputs-right-input">
                                    <div className="inputs-schedule">
                                        <label>Base:</label>
                                        <select className="flat-theme">
                                            <option label="UTC-SP" value="utc-sp">UTC-SP</option>
                                            <option label="UTC-NY" value="utc-ny">UTC-NY</option>
                                            <option label="UTC-0" value="utc-0">UTC-0</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="container-schedule-inputs-right-input">
                                    <div className="inputs-schedule">
                                        <label>Value:</label>
                                        <select className="flat-theme">
                                            <option label="UTC-SP" value="utc-sp">UTC-SP</option>
                                            <option label="UTC-NY" value="utc-ny">UTC-NY</option>
                                            <option label="UTC-0" value="utc-0">UTC-0</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ListSched bot={id} switchlist={this.state.switchList}/>
                </div>
            </div>
        );
    }
}

export default Schedule;
