import React from 'react';
import axios from "axios";
import RowData from "./RowData";
import {getUrlToken} from "../../Auth/Auth";


const api = axios.create({
    baseURL: 'https://station.datallog.com/api/exec',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class DataExec extends React.Component {

    constructor(props) {
        super(props);
        this.state = {rows: [], loading: true}
        this.reloadClick = this.reloadClick.bind(this);
        this._isMounted = false;
    }

    // Function to call list of bots
    getData = async () => {
        // Build url
        const url = '/' + this.props.exec

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {
            console.log(res);
        })

        if (data && this._isMounted) {
            // Pushing fetched data in bot array and update offset
            this.setState({rows: data.data.data, loading: false});
        }

    }

    reloadClick(e) {
        e.preventDefault();
        this.setState({loading: true}, function () {
            this.getData();
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <div className="overlay-card-bot">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"></i><span
                    className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>;
        } else if (this.state.rows.length) {
            content = this.state.rows.map(url =><RowData key={url} link={url} />);
        } else {
            content = <div className="overlay-card-bot-no-data"> <i className="fas fa-robot no-data" />
                <span className="span-no-data">No data</span></div>
        }

        return (
            <div className="container-data-log-exec-left">
                <ul className="dt-tabs-list">
                    <li className="dt-tabs-title-collector">
                        <span>Data</span><a href="#" onClick={this.reloadClick} ><i className="material-icons reload-exec">refresh</i></a>
                    </li>
                </ul>
                <div className="content-extractor-exec-links">
                    {content}
                </div>
            </div>
        );
    }
}

export default DataExec;

