import React from 'react';
import axios from "axios";
import MonitorExecProgress from "../../highchart/MonitorExecProgress";
import StepsProcessed from "../../highchart/StepsProcessed";
import LogsByStep from "../../highchart/LogsByStep";
import ProgressExec from "./ProgressExec";
import DataExec from "./DataExec";
import LogExec from "./LogExec";
import DebugExec from "./DebugExec";
import {getUrlToken} from "../../Auth/Auth";
import StopExec from "../../Modals/StopExec";
import BtnStopExec from "../../Buttons/BtnStopExec";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class DashExec extends React.Component {

    constructor(props) {
        super(props);
        this.state = {steps_processed: [], logs_by_step: [], is_running: false, debug: false, steps_processing:[],
            info:{status: "loading", transfer_data: "0 mb", time_running: "0 seconds", info: ["info-ok", "ok"],
                isRunning: false}, total_finished: 0, total_to_process: 0, total_logs: 0, percentage_process: 0,
            percentage_data: 0, percentage_log: 0, show_modal: false, exec_in_context:0, id: this.props.match.params.id,
            bot_id: this.props.match.params.bot }
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({ show_modal: true});
    };

    hideModal = () => {
        this.setState({ show_modal: false});
    };

    stopExec = async () => {
        this.setState({ show_modal: false});
        let bot_stop = {bot_id: this.state.bot_id, exec_id: this.props.match.params.id}
        const url = '/stop/exec'
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        await api.post(url, bot_stop, config).then(res => {
            this.getExec();
        }).catch(res => {
            console.log(res);
        })
    }

    // Function to call list of bots
    getExec = async () => {
        // Build url
        const url = '/status-exec/'+this.props.match.params.id

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})

        if (data && this._isMounted) {
            // Pushing fetched data in bot array and update offset
            this.setState({steps_processed: data.steps_already_processed, logs_by_step: data.logs_by_step,
                is_running: data.info.isRunning, debug: data.info.debug, steps_processing: data.steps_processing, info: data.info,
                total_finished: data.total_finished, total_to_process: data.total_to_process,
                total_logs: data.total_logs, percentage_process: data.percentage_process,
                percentage_data: data.percentage_data, percentage_log: data.percentage_log})
        }

    }

    genNewValues = () => {
       if (this.state.is_running) {
           this.getExec();
       }

    }

    componentDidMount() {
        this._isMounted = true;
        this.getExec();
        this.timerUpdate = setInterval(this.genNewValues, 5000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timerUpdate);
    }

    render() {
        const {id} = this.props.match.params
        const {bot} = this.props.match.params
        let exec_progress;
        if (this.state.is_running) {
            exec_progress = <MonitorExecProgress info={this.state.steps_processing} exec_id={id}/>
        } else {
            exec_progress = <div className="info-exec-box-column">
                <h2 className="box-extractor-name">Exec Progress</h2>
                <div className="chart-exec-box">
                    <div className="overlay-card-bot-no-data">
                        <i className="fas fa-robot no-data" /><span className="span-no-data">It's not running</span>
                    </div>
                </div>
            </div>
        }

        return (
            <div id="execution">
                <StopExec show={this.state.show_modal} handleClose={this.hideModal} handleStop={this.stopExec}/>
                <div id="container-execution">
                    <div className="name-actions-exec">
                        <div id="name-exec">
                            <div>
                               <span>{id}</span> <span className={this.state.info.info[0]}><span>{this.state.info.info[1]}</span></span>
                            </div>
                            {/*<label>Bot: Linkedin collector</label>*/}
                        </div>
                        <div className="actions-exec">
                            <BtnStopExec exec_id={this.state.id} handleStop={this.showModal} />
                        </div>
                    </div>
                    <div className="container-column-exec">
                        <div className="left-column-exec">
                            <div className="info-exec">
                                {exec_progress}
                                <div className="info-exec-box-row">
                                    <StepsProcessed info={this.state.steps_processed}/>
                                    <LogsByStep info={this.state.logs_by_step}/>
                                </div>
                            </div>
                        </div>
                        <div className="right-column-exec">
                            <ProgressExec info={this.state.info} total_finished={this.state.total_finished}
                                          total_logs={this.state.total_logs}
                                          total_to_process={this.state.total_to_process}
                                          percentage_process={this.state.percentage_process}
                                          percentage_data={this.state.percentage_data}
                                          percentage_log={this.state.percentage_log}/>
                            <div className="container-data-log-exec">
                                <DataExec exec={id}/>
                                <LogExec exec={id}/>
                            </div>
                            <DebugExec exec={id} bot={bot} running={this.state.is_running} debug={this.state.debug}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashExec;

