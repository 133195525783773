import React from 'react';

const ExecBar = (props) => {
    return (
        <div className="exec-progress-bar">
            <div className="cont-exec-progress-bar">
                <div className="div-exec-progress-bar-data" style={{width: props.width_data+"%"}}/>
                <div className="div-exec-progress-bar-log" style={{width: props.width_log+"%"}}/>
            </div>
        </div>
    );
}

export default ExecBar;