import React from 'react';
import {Link} from "react-router-dom";

const BtnAdd = (props) => {
  return (
      <Link to={`/dashboard/schedule/${props.bot}`}><button className="btn-add">Add Schedule</button></Link>
  );
}

export default BtnAdd;
