import React from 'react';
import SideBarBot from "./SideBarBot";

const SideBarBots = () => {
    return  (
        <li className="scrollable-li">
            <nav>
                    <SideBarBot/>
            </nav>
        </li>
    );
}

export default SideBarBots;
