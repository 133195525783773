import React from 'react';

const CompleteRowSched = (props) => {
    return (
        <div className="row-content-extractor-schedule-2">
            <div className="row-column-content-extractor-schedule">{props.utc}</div>
            <div className="row-column-content-extractor-schedule">{props.day}</div>
            <div className="row-column-content-extractor-schedule">{props.start_in}</div>
            <div className="row-column-content-extractor-schedule actions">
                <a href="#" onClick={()=>props.handleDelete(props.startime)}><i className='far fa-trash-alt'/></a>
            </div>
        </div>
    );
}

export default CompleteRowSched;
