import React from 'react';
import axios from "axios";
import {getUrlToken} from "../../Auth/Auth";
import RowDebug from "./RowDebug";


const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class DebugExec extends React.Component {

    constructor(props) {
        super(props);
        this.state = {rows: [], loading: true, offset_top_to_bottom: 0, offset_bottom: 0}
        this._isMounted = false;
        this._isBottomCalled = false;
    }

    // Function to call list of messages
    getTopDebug = async () => {
        if (this.state.offset_bottom === 0) {
            this.getFirstTopToBottomDebug();
        } else {
            const url = '/debug/bottom/'+this.props.bot+'/'+this.props.exec+'?limit=20&offset='+this.state.offset_bottom
            let data = false;
            let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
            data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})
            if (data && this._isMounted) {
                let new_rows = []
                let new_offset = 0
                if (data.items.length > 0) {
                    new_offset = data.items[data.items.length-1]['timestamp']
                    for (let i = data.items.length; i > 0; i--) {
                        if(data.items[i-1]['timestamp'] !== this.state.offset_bottom) {
                            new_rows.push(data.items[i-1])
                        }
                    }
                }
                for (let i = 0; i < this.state.rows.length; i++) {
                    new_rows.push(this.state.rows[i])
                }

                new_rows = this.removeDuplicates(new_rows, 'timestamp')
                if (new_offset > 0) {
                    this.setState({rows: new_rows, loading: false, offset_bottom: new_offset});
                } else {
                    this.setState({rows: new_rows, loading: false});
                }
            }
        }
    }

    getFirstTopToBottomDebug = async () => {
        const url = '/debug/top/'+this.props.bot+'/'+this.props.exec+'?limit=10'
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})
        if (data && this._isMounted) {
            let new_rows = []
            let new_offset_bottom = 0
            for (let i = 0; i < this.state.rows.length; i++) {
                new_rows.push(this.state.rows[i])
            }
            for (let i = 0; i < data.items.length; i++) {
                if (i === 0) {
                    new_offset_bottom = data.items[i]['timestamp']
                }
                new_rows.push(data.items[i])
            }
            this.setState({rows: new_rows, offset_top_to_bottom: data.offset, loading: false, offset_bottom: new_offset_bottom});
        }
    }


    getTopToBottomDebug = async () => {
        if (this.state.offset_top_to_bottom !== 0) {
            const url = '/debug/top/'+this.props.bot+'/'+this.props.exec+'?limit=10&offset='+this.state.offset_top_to_bottom
            let data = false;
            let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
            data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})
            if (data && this._isMounted) {
                let new_rows = []
                for (let i = 0; i < this.state.rows.length; i++) {
                    new_rows.push(this.state.rows[i])
                }
                for (let i = 0; i < data.items.length; i++) {
                    // Fixing dynamo db pagination
                    if (i > 0) {
                        new_rows.push(data.items[i])
                    }
                }
                this._isBottomCalled = false;
                this.setState({rows: new_rows, offset_top_to_bottom: data.offset, loading: false});
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if(this.props.running && this.props.debug) {
            this.timerUpdate = setInterval(this.getTopDebug, 5000);
        }
        this.getFirstTopToBottomDebug();
        window.addEventListener('scroll', this.handleOnScroll)
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timerUpdate);
        window.removeEventListener('scroll', this.handleOnScroll)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.running !== this.props.running) {
            if (this.props.running && this.props.debug) {
                this.timerUpdate = setInterval(this.getTopDebug, 5000);
            } else {
                clearInterval(this.timerUpdate);
            }
        }
    }

    // handle onScroll event
    handleOnScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            if (!this._isBottomCalled) {
                this.getTopToBottomDebug();
                this._isBottomCalled = true;
            }
        }
    }

    removeDuplicates = (originalArray, prop) => {
        let newArray = [];
        let lookupObject  = {};

        for(let i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for(let i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }


    render() {
        let content;
        if (this.state.loading) {
            content = <div className="overlay-card-bot">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"></i><span
                    className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>;

        } else if (this.state.rows.length) {
            content = this.state.rows.map(row =><RowDebug key={row.timestamp} step={row.obj+'-'+row.step} hour={row.hour} message={row.message} message_type={row.messsage_type} />);
        } else {
            content = <div className="overlay-card-bot-no-data"> <i className="fas fa-robot no-data" />
                <span className="span-no-data">No data</span></div>
        }

        return (
            <div className="container-debug-exec">
                <ul className="dt-tabs-list">
                    <li className="dt-tabs-title-collector">
                        <span>Debug</span><i className="material-icons reload-exec">refresh</i>
                    </li>
                </ul>
                <div className="head-debug">
                    <div className="head-column-debug-step">Obj-Step</div>
                    <div className="head-column-debug-hour">Hour</div>
                    <div className="head-column-debug-message">Message</div>
                </div>
                <div onScroll={this.handleOnScroll} className="content-extractor-exec-collector">
                    {content}
                </div>
            </div>
        );
    }
}

export default DebugExec;

