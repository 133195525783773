import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './css/position.css';
import './css/style.css';
import './css/station_login.css';
import {createStore} from "redux";
import allReducers from './reducers';
import {Provider} from 'react-redux';

const store = createStore(allReducers);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

