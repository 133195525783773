import React from 'react';
import Routes from "../Router/Routes";

const MainContent = () => {
    return (
        <div className="main-content">
                <Routes/>
        </div>
    );
}

export default MainContent;
