import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


class MonitorExecProgress extends React.Component {

    constructor(props) {
        super(props);
    }

    afterChartCreated = (chart) => {
        this.internalChart = chart;
    }

    genNewValues = () => {
        let x = (new Date()).getTime();
        this.props.info.forEach((item, index) => {
            this.internalChart.series[item['id']].addPoint([x, item['y']], true, true);
        })
    }

    componentDidMount() {
        if (this.props.info.length > 0) {
            this.internalChart.update({series: this.getStartSeries(this.props.info.length)}, true, true);
        }
        this.timerUpdate = setInterval(this.genNewValues, 2000);
    }
    componentWillUnmount() {
        clearInterval(this.timerUpdate);
    }

    getStartSeries = (number_of_series) => {
        let series = [], i;
        for (i=0; i < number_of_series; i++) {
            let row = {
                name: 'step-' + i,
                data: (function () {
                    // generate inicial data
                    let data = [], time = (new Date()).getTime(), i;
                    for (i = -10; i <= 0; i += 1) { data.push({ x: time + i * 1000, y: 0});}
                    return data;
                }())
            }
            series.push(row)
        }
        return series
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.exec_id === nextProps.exec_id) {
            if((this.props.info.length === 0) && (nextProps.info.length > 0)) {
                this.internalChart.update({series: this.getStartSeries(nextProps.info.length)}, true, true);
            }
            return false;
        }
        return true;
    }

    render() {
        const options = {
            chart: {
                type: 'line',
                animation: Highcharts.svg,
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150
            },
            yAxis: {
                title: null,
                plotLines: [{
                    value: 0,
                    width: 1,

                }]
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top'
            },
            series: []
        }

        return (
            <div className="info-exec-box-column">
                <h2 className="box-extractor-name">Exec Progress</h2>
                <div className="chart-exec-box">
                    <HighchartsReact
                        containerProps={{ style: { height: "100%" } }}
                        highcharts={Highcharts}
                        options={options}
                        callback={ this.afterChartCreated }
                    />
                </div>
            </div>
        );
    }
}

export default MonitorExecProgress;