import React from 'react';
import axios from "axios";
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/user',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class SideBarHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {email: "datallog.com"}
    }

    // Function to call list of bots
    getInfo = async () => {
        // Build url
        const url = '/info'

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})

        if (data) {
            // Pushing fetched data
            this.setState({email: data.email})
        }

    }

    componentDidMount() {
        this.getInfo();
    }

    render() {
        return (
            <div className="side-bar-header">
                <div className="icon-person-side-bar">
                    <i className="material-icons icon-person">person</i>
                </div>
                <div className="profile-side-bar">
                    <span id="user-email">{this.state.email}</span><br></br>
                    <a href="#"><span>PROFILE</span></a>
                </div>
            </div>
        );
    }
}

export default SideBarHeader;
