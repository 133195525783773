import React from 'react';
import {Link} from "react-router-dom";

const RowExec = (props) => {
    return (
        <div className="row-content-extractor-exec-collector">
            <div className="row-column-content-extractor-exec-collector"><Link to={`/dashboard/exec/${props.bot_id}/${props.exec_id}`}>{props.exec_id}</Link></div>

            <div className="row-column-content-extractor-exec-collector">{props.info}</div>
            <div className="row-column-content-extractor-exec-collector log"><span
                className={props.status}><span>Ok</span></span></div>
        </div>
    );
}

export default RowExec;
