import React from 'react';
import axios from "axios";
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class DashBotSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true, status: false, debug: false, parallelism: 0, new_parallelism: 0, status_webhook: false,
            url_webhook: null, edit_parallelism: false, edit_webhook: false,
            alert_parallelism: "alert-parallelism-hidden", new_webhook: "", alert_webhook: "alert-webhook-hidden",
            display_loading: "", message_error: "", error_parallelism: "alert-parallelism-hidden"
        }
        this._isMounted = false;
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleDebugChange = this.handleDebugChange.bind(this);
        this.handleChangeParallelism = this.handleChangeParallelism.bind(this);
        this.handleChangeWebhook = this.handleChangeWebhook.bind(this);
        this.handleCancelWebhook = this.handleCancelWebhook.bind(this);
        this.handleCancelParallelism = this.handleCancelParallelism.bind(this);
        this.handleChangeParallelismValue = this.handleChangeParallelismValue.bind(this);
        this.handleChangeWebhookValue = this.handleChangeWebhookValue.bind(this);
        this.handleSubmitNewParallelismValue = this.handleSubmitNewParallelismValue.bind(this);
        this.handleSubmitNewWebhookValues = this.handleSubmitNewWebhookValues.bind(this);
    }

    // Function to get info of bot settings
    getBotInfo = async () => {
        // Build url
        const url = '/bot/info/' + this.props.bot
        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {
            console.log(res);
        })

        if (data && this._isMounted) {
            // Pushing fetched data in settings bot
            if (data.bot.webhook) {
                this.setState({
                    loading: false, status: data.bot.info.status, debug: data.bot.info.debug,
                    parallelism: data.bot.info.parallelism, new_parallelism: data.bot.info.parallelism,
                    status_webhook: data.bot.webhook.status, url_webhook: data.bot.webhook.url,
                    new_webhook: data.bot.webhook.url, display_loading: "d-none"
                })
            } else {
                this.setState({
                    loading: false, status: data.bot.info.status, debug: data.bot.info.debug,
                    parallelism: data.bot.info.parallelism, display_loading: "d-none"
                })
            }
        }
    }

    // Function to update info of bot
    updateBotInfo = async (info) => {
        // Build url
        const url = '/bot/update/' + this.props.bot
        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.patch(url, info, config).then(({data}) => data).catch(res => {
            console.log(res.response.data.message);
            this.setState({display_loading:"d-none"});
        })

        if (data && this._isMounted) {
            this.setState({status: data.status, debug:data.debug, parallelism: data.parallelism,
                display_loading:"d-none"})
        }
    }

    // Function to update info of bot
    updateParallelismBotInfo = async (info) => {
        // Build url
        const url = '/bot/update/' + this.props.bot
        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.patch(url, info, config).then(({data}) => data).catch(res => {
            this.setState({display_loading:"d-none", error_parallelism: "alert-parallelism", message_error:res.response.data.message});
        })

        if (data && this._isMounted) {
            this.setState({status: data.status, debug:data.debug, parallelism: data.parallelism,
                display_loading:"d-none", alert_parallelism: "alert-parallelism-hidden", edit_parallelism: false})
        }
    }

    // Function to update info of bot
    updateWebhook = async (info) => {
        // Build url
        const url = '/webhook/' + this.props.bot
        // Put response in local data
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        await api.patch(url, info, config).then(res => {
            this.setState({display_loading: "d-none", alert_webhook: "alert-webhook-hidden", edit_webhook: false});
            this.getBotInfo();
        }).catch(res => {
            this.setState({display_loading: "d-none", alert_webhook: "alert-webhook"});
        })
    }

    // Life cycles components
    componentDidMount() {
        this._isMounted = true;
        this.getBotInfo();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // Control functions component
    handleStatusChange = (e) => {
        this.setState({status: e.target.checked});
        let info = {status: e.target.checked}
        this.setState({display_loading:""});
        this.updateBotInfo(info);
    }

    handleDebugChange = (e) => {
        this.setState({debug: e.target.checked});
        let debug = {debug: e.target.checked}
        this.setState({display_loading:""});
        this.updateBotInfo(debug);
    }

    handleChangeParallelism = (e) => {
        e.preventDefault();
        this.setState({edit_parallelism: true});
    }

    handleCancelParallelism = (e) => {
        e.preventDefault();
        this.setState({edit_parallelism: false, alert_parallelism: "alert-parallelism-hidden",
            error_parallelism: "alert-parallelism-hidden"})
    }

    handleChangeWebhook = (e) => {
        e.preventDefault();
        this.setState({edit_webhook: true})
    }

    handleCancelWebhook = (e) => {
        e.preventDefault();
        this.setState({edit_webhook: false, alert_webhook: "alert-webhook-hidden"})
    }

    handleChangeParallelismValue(e) {
        this.setState({
            new_parallelism: e.target.value
        })
    }

    handleChangeWebhookValue(e) {
        this.setState({
            new_webhook: e.target.value
        })
    }

    handleStatusWebhookChange = (e) => {
        this.setState({status_webhook: e.target.checked});
    }

    handleSubmitNewParallelismValue(e) {
        if(this.state.new_parallelism % 5) {
            this.setState({alert_parallelism: "alert-parallelism"});
        } else {
            if (this.state.new_parallelism <= 0) {
                this.setState({alert_parallelism: "alert-parallelism"});
            } else {
                let info = {parallelism: this.state.new_parallelism}
                this.updateParallelismBotInfo(info);
                this.setState({alert_parallelism: "alert-parallelism-hidden",
                    error_parallelism: "alert-parallelism-hidden", display_loading: ""});
            }
        }
    }

    handleSubmitNewWebhookValues(e) {
        if (this.state.status_webhook) {
            if (this.isValidHttpUrl(this.state.new_webhook)) {
                this.setState({display_loading: ""})
                let info = {url: this.state.new_webhook, status: this.state.status_webhook}
                this.updateWebhook(info);
            } else {
                this.setState({alert_webhook: "alert-webhook"});
            }
        } else {
            this.setState({display_loading: ""})
            let info = {status: this.state.status_webhook}
            this.updateWebhook(info);
        }
    }

    isValidHttpUrl(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    componentDidUpdate(prevProps) {
        if(prevProps.bot !== this.props.bot)
        {
            this.setState({display_loading: "", edit_parallelism: false, edit_webhook: false}, function () {
                this.getBotInfo();
            })
        }
    }


    render() {
        if (this.state.edit_parallelism) {
            return (<div className="edit-settings-collector">
                <ul className="dt-tabs-list">
                    <li className="dt-tabs-title-collector">
                        <span>Parallelism <span className={this.state.alert_parallelism}> (Must be greater than zero and multiple of 5)</span>
                            <span className={this.state.error_parallelism}> ({this.state.message_error})</span> </span>
                    </li>
                </ul>
                <div className={`overlay-card-bot-settings ${this.state.display_loading}`}>
                    <div className="overlay-card-bot-loading">
                        <i className="fas fa-robot loading" /><span
                        className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                    </div>
                </div>

                <div className="edit-info-parallelism">
                    <div className="column-info-parallelism">
                        <label>Parallelism: <span className="old-parallelism">{this.state.parallelism}</span></label>
                        <label>New Parallelism:</label>
                        <input type="text" id="info-parallelism" onChange={this.handleChangeParallelismValue} value={this.state.new_parallelism}/>
                    </div>
                    <div className="column-info-parallelism-actions">
                        <button  onClick={this.handleSubmitNewParallelismValue} className="btn-add-vertical">Confirm</button>
                        <button  onClick={this.handleCancelParallelism} className="btn-delete">Cancel</button>
                    </div>
                </div>
            </div>);
        } else if (this.state.edit_webhook) {
            return (<div className="edit-settings-collector">
                <ul className="dt-tabs-list">
                    <li className="dt-tabs-title-collector">
                        <span>Webhook <span className={this.state.alert_webhook}>(Must be a valid url http/https)</span> </span>
                    </li>
                </ul>

                <div className={`overlay-card-bot-settings ${this.state.display_loading}`}>
                    <div className="overlay-card-bot-loading">
                        <i className="fas fa-robot loading" /><span
                        className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                    </div>
                </div>

                <div className="edit-info-parallelism">
                    <div className="column-info-webhook">
                        <label className="column-info-webhook-label">Status:  <label className="switch"><input type="checkbox" checked={this.state.status_webhook} onChange={this.handleStatusWebhookChange} /><span
                            className="slider round"/></label> </label>
                        <div className="row-info-webhook">
                            <div className="column-info-row-webhook">
                                <label>Webhook:</label>
                                <input type="text" id="info-webhook" onChange={this.handleChangeWebhookValue} value={this.state.new_webhook}/>
                            </div>
                        </div>
                    </div>

                    <div className="column-info-parallelism-actions">
                        <button onClick={this.handleSubmitNewWebhookValues} className="btn-add-vertical">Confirm</button>
                        <button onClick={this.handleCancelWebhook} className="btn-delete">Cancel</button>
                    </div>
                </div>
            </div>);
        } else {
            let status_webhook = this.state.status_webhook ? "(on)" : "(off)";
            let url_webhook = this.state.url_webhook ? this.state.url_webhook : "https://www.";
            return (
                <div className="settings-collector">
                    <ul className="dt-tabs-list">
                        <li className="dt-tabs-title-collector">
                            <span>Settings</span>
                        </li>
                    </ul>
                    <div className={`overlay-card-bot-settings ${this.state.display_loading}`}>
                        <div className="overlay-card-bot-loading">
                            <i className="fas fa-robot loading" /><span
                            className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                        </div>
                    </div>
                    <div className="info-collector">
                        <div className="column-info-collector-key">
                            <label>On/Off:</label>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.status} onChange={this.handleStatusChange}/>
                                <span className="slider round"/>
                            </label>
                        </div>
                        <div className="column-info-collector-key">
                            <label>Debug:</label>
                            <label className="switch">
                                <input type="checkbox" checked={this.state.debug} onChange={this.handleDebugChange}/>
                                <span className="slider round"/>
                            </label>
                        </div>
                        <div className="column-info-collector">
                            <label>Parallelism : <a onClick={this.handleChangeParallelism}><i className='far fa-edit'/></a></label>
                            <span id="collector-runing-now">{this.state.parallelism}</span>
                        </div>
                        <div className="column-info-collector">
                            <label>Webhook : <a onClick={this.handleChangeWebhook}><i
                                className='far fa-edit'/></a></label>
                            <span id="webhook-collector"><span> {status_webhook} </span> {url_webhook}</span>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

export default DashBotSettings;
