import React from 'react';
import SideBarSearch from "./SideBarSearch";
import SideBarMenu from "./SideBarMenu";

const SideBarMain = () => {
    return  (
        <div className="side-bar-main">
            <SideBarSearch/>
            <SideBarMenu/>
        </div>
    );
}

export default SideBarMain;
