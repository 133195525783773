import React from 'react';
import BtnAdd from "../Buttons/BtnAdd";
import BtnDelete from "../Buttons/BtnDelete";
import MonitorStepsToRun from "../highchart/MonitorStepsToRun";
import MonitorStepsRunning from "../highchart/MonitorStepsRunning";
import MonitorRequestRunning from "../highchart/MonitorRequestRunning";
import StatisticsRequest from "../highchart/StatisticsRequest";
import DashBotSettings from "./DashBotSettings";
import DashBotExec from "./DashBotExec";
import DashBotSched from "./DashBotSched";
import DeleteBot from "../Modals/DeleteBot";
import { Redirect } from "react-router-dom"
import axios from "axios";
import {getUrlToken} from "../Auth/Auth";
import {updateAction} from "../../actions";
import {connect} from "react-redux";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class DashBot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {id: this.props.match.params.id, show_modal: false, delete_bot_in_context:0, redirect: false}
    }

    showModal = (id) => {
        this.setState({ show_modal: true, delete_bot_in_context: id});
    };

    hideModal = () => {
        this.setState({ show_modal: false});
    };

    deleteBot = async () => {
        const url = '/bot/delete/'+this.state.delete_bot_in_context
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        await api.delete(url, config).then(res => {
            this.setState({ show_modal: false, redirect: true});
            this.props.dispatch(updateAction());
        }).catch(res => {
            this.setState({ show_modal: false, redirect: true});
            this.props.dispatch(updateAction());
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({id: this.props.match.params.id, show_modal: false, delete_bot_in_context:0,
                redirect: false})
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/dashboard" />
        } else {
            return (
                <div id="extractor">
                    <DeleteBot show={this.state.show_modal} handleClose={this.hideModal} handleDelete={this.deleteBot}/>
                    <div className="name-actions-collector">
                        <div id="name-collector">{this.props.location.name}</div>
                        <div className="actions-collector">
                            <BtnDelete bot={this.state.id} handleDelete={this.showModal}/>
                            <BtnAdd bot={this.state.id}/>
                        </div>
                    </div>
                    <div className="container-column-collector">
                        <div className="left-column-collector">
                            <div className="info-collector">
                                <div className="info-exec-box">
                                    <h2 className="box-extractor-name">Steps to run</h2>
                                    <div className="chart-exec-box">
                                        <MonitorStepsToRun bot={this.state.id}/>
                                    </div>
                                </div>
                                <div className="info-exec-box">
                                    <h2 className="box-extractor-name">Steps running</h2>
                                    <div className="chart-exec-box">
                                        <MonitorStepsRunning bot={this.state.id}/>
                                    </div>
                                </div>
                                <div className="info-exec-box">
                                    <h2 className="box-extractor-name">Requesters running</h2>
                                    <div className="chart-exec-box">
                                        <MonitorRequestRunning bot={this.state.id}/>
                                    </div>
                                </div>
                                <div className="info-exec-box">
                                    <h2 className="box-extractor-name">Request statistics</h2>
                                    <div className="chart-exec-box">
                                        <StatisticsRequest bot={this.state.id}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-column-collector">
                            <DashBotSettings bot={this.state.id}/>
                            <DashBotExec bot={this.state.id}/>
                            <DashBotSched bot={this.state.id}/>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default connect()(DashBot);
