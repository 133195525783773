import React from 'react';

const MainHeader = () => {
  return (
      <div className="main-header">
        <h1>Dashboard</h1>
      </div>
  );
}

export default MainHeader;
