import React from 'react';
import MainContainer from './Main/MainContainer';
import SideBar from './SideBar/SideBar';

class Aplication extends React.Component {
    render() {
        return <>
            <SideBar/>
            <MainContainer/>
        </>
    }
}

export default Aplication;
