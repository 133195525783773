import React from 'react';
import CardBot from "./CardBot";
import axios from "axios";
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {cards: [], offset: 0, limit: 8, loading: true}
        this._isMounted = false;
    }

    // Function to call list of bots
    getCards = async () => {
        // Build url
        const url = '/bots?limit=' + this.state.limit + '&offset=' + this.state.offset

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})

        if (data && this._isMounted) {
            // Pushing fetched data in bot array and update offset
            let newOffset = this.state.offset + this.state.limit;
            this.setState({cards: [...this.state.cards, ...data.results], loading: false, offset: newOffset})
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getCards();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <div className="overlay-card-bot">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"></i><span
                    className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>;
        } else if (this.state.num_execs === 0) {
            content = <div className="overlay-card-bot-no-data"><i className="fas fa-robot no-data"></i><span
                className="span-no-data">No data</span></div>;
        } else {
            content = this.state.cards.map(bot =>
                <CardBot key={bot.id} bot_name={bot.name}
                         debug={bot.debug}
                         status={bot.status}
                         status_last_exec={"info-ok"}
                         info_last_exec={"Completed"}
                         id={bot.id}
                />
            );
        }

        return (
            <div id="dashboard">
                {content}
            </div>
        );
    }
}

export default Dashboard;
