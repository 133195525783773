import React from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import {getUrlToken} from "../../Auth/Auth";
import {connect} from "react-redux";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})


class SideBarBot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {bots: [], offset: 0, limit: 100, loading: true}
    }

    // Function to call list of bots
    getBots = async () => {

        // Build url
        const url = '/bots?limit=' + this.state.limit + '&offset=' + this.state.offset

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {
            console.log(res);
        })

        if (data) {
            // Pushing fetched data in bot array and update offset
            let newOffset = this.state.offset + this.state.limit;
            this.setState({bots: [...this.state.bots, ...data.results], offset: newOffset, loading: false})

            if (data.next) {
                this.getBots();
            }
        }
    }

    componentDidMount() {
        this.getBots();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trigger !== this.props.trigger) {
            this.setState({bots: [], offset: 0, limit: 100, loading: true}, function () {
                this.getBots();
            })
        }
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <div className="overlay-card-bot">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"/><span
                    className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>;
        } else if (this.state.bots === 0) {
            content = <div className="overlay-card-bot-no-data"><i className="fas fa-robot no-data"/><span
                className="span-no-data">No data</span></div>;
        } else {
            content =
                <ul>
                    {
                        this.state.bots.map(bot =>
                            <li key={bot.id}>
                                <div className="div-bot-name"><Link to={ { pathname:`/dashboard/bot/${bot.id}`, name: bot.name}} className="bot-link">{bot.name}</Link></div>
                            </li>
                        )
                    }
                </ul>
        }
        return (content);
    }
}

function mapStateToProps(state) {
    return {
        trigger: state.botReducer.trigger
    }
}

export default connect(mapStateToProps)(SideBarBot);
