import React from 'react';
import MainHeader from "./MainHeader";
import MainContent from "./MainContent";

class MainContainer extends React.Component {
  render() {
    return <div className="main"> <MainHeader/> <MainContent/> </div>;
  }
}

export default MainContainer;
