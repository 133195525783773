import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from "axios";
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api/request',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class MonitorRequestRunning extends React.Component {

    constructor(props) {
        super(props);
        this.reference_value = 0;
        this.state = {offset: 0, limit: 8}
        this._isMounted = false;
    }

    // Function to call value
    getValue = async () => {
        // Build url
        const url = '/count-request-running/' + this.props.bot
        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})
        if (data && this._isMounted) {
            // Pushing fetched data in bot array and update offset
            this.reference_value = data.count;
        }
    }

    afterChartCreated = (chart) => {
        this.internalChart = chart;
    }
    genNewValues = () => {
        let x = (new Date()).getTime();
        let y = this.reference_value;
        this.internalChart.series[0].addPoint([x, y], true, true);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.bot !== this.props.bot)
        {
            this.reference_value = 0;
            this.getValue();
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getValue();
        this.timerUpdate = setInterval(this.genNewValues, 2000);
        this.timerRequest = setInterval(this.getValue, 10000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timerUpdate);
        clearInterval(this.timerRequest);
    }

    static getConfig = () => ({
        chart: {
            type: 'area',
            animation: Highcharts.svg,
        },
        title: {
            text: null
        },
        xAxis: {
            type: 'datetime',
            tickPixelInterval: 150
        },
        yAxis: {
            title: null,
            plotLines: [{
                value: 0,
                width: 1,

            }]
        },
        legend:{ enabled:false },

        series: [{
            lineColor: '#395A7A',
            color: '#395A7A',
            fillOpacity: 0.3,
            data: (function () {
                // generate inicial data
                let data = [],
                    time = (new Date()).getTime(),
                    i;

                for (i = -10; i <= 0; i += 1) {
                    data.push({
                        x: time + i * 1000,
                        y: 0
                    });
                }
                return data;
            }())
        }]
    })

    render() {
        return (
            <HighchartsReact
                containerProps={{ style: { height: "100%" } }}
                highcharts={Highcharts}
                options={MonitorRequestRunning.getConfig()}
                callback={ this.afterChartCreated }
            />
        );
    }
}

export default MonitorRequestRunning;