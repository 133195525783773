import React from 'react';

const DeleteBot = ({handleClose, handleDelete, show}) => {
    const showHideClassName = show ? "overlay-modal-delete-bot-display-flex" : "overlay-modal-delete-display-none";

    return (
        <div className={showHideClassName}>
            <div className="body-modal-delete-bot">
                <div className="modal-header">
                    <button type="button" onClick={handleClose} className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3><span className="featured">Remove Bot</span></h3>
                </div>
                <div className="modal-body">
                    <p>You are about to delete a bot!</p>
                </div>
                <div className="modal-footer">
                    <a className="flat-btn span2 no-margin" href="#" onClick={handleClose} data-dismiss="modal"
                       aria-hidden="true">
                        Cancel
                    </a>
                    <button onClick={handleDelete} className="flat-btn flat-btn-danger span3 pull-right" data-dismiss="modal"
                            aria-hidden="true">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteBot;
