import React from 'react';
import axios from "axios";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api/request',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})


class StatisticsRequest extends React.Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    getValue = async () => {
        // Build url
        const url = '/statistics/' + this.props.bot
        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})
        if (data && this._isMounted) {
            // Pushing fetched data in bot array and update offset
            this.internalChart.series[0].setData(data);
        }

    }

    afterChartCreated = (chart) => {
        this.internalChart = chart;
    }

    componentDidUpdate(prevProps) {
        if(prevProps.bot !== this.props.bot)
        {
            this.getValue();
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getValue();
        this.timerRequest = setInterval(this.getValue, 60000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timerRequest);
    }

    static getConfig = () => ({
        chart: {
            type: 'column',
        },
        title: {
            text: null
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: false
        },
        legend:{ enabled:false },

        series: [{
            data: [
                {
                    name: "200",
                    y: 0,
                    color: '#DDF1DE'
                },
                {
                    name: "404",
                    y: 0,
                    color: '#FCD9E0'
                },
            ]
        }]
    })

    render() {
        return (
            <HighchartsReact
                containerProps={{ style: { height: "100%" } }}
                highcharts={Highcharts}
                options={StatisticsRequest.getConfig()}
                callback={ this.afterChartCreated }
            />
        );
    }


}

export default StatisticsRequest;
