import React from 'react';
import SideBarHeader from "./SideBarHeader";
import SideBarMain from "./Main/SideBarMain";
import SideBarFooter from "./SideBarFooter";

const SideBar = (props) => {
  return (
      <div className="side-bar">
        <SideBarHeader email={"joelder.arcaro@datallog.com"}/>
        <SideBarMain/>
        <SideBarFooter/>
      </div>
  );
}

export default SideBar;
