import React from 'react';

const RowExec = (props) => {
    return (
        <div className="row-content-extractor-exec-collector">
            <div className="row-column-content-extractor-exec-data"><a href={props.link} target="_blank"> {props.link}</a></div>
        </div>
    );
}

export default RowExec;
