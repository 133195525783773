import React from 'react';

const RowDebug = (props) => {

    if (props.message_type === 'ok') {
        return (
            <div className="row-content-debug-exec-collector">
                <div className="row-column-content-debug-step">{props.step}</div>
                <div className="row-column-content-debug-hour">{props.hour}</div>
                <div className="row-column-content-debug-message">{props.message}</div>
            </div>
        );
    } else {
        return (
            <div className="row-content-debug-exec-collector">
                <div className="row-column-content-debug-step">{props.step}</div>
                <div className="row-column-content-debug-hour">{props.hour}</div>
                <div className="row-column-content-debug-message-error">{props.message}</div>
            </div>
        );
    }
}

export default RowDebug;
