export const isAuth = () => {
    if (sessionStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

export const getUrlToken = () => {
    const token = "Token " + sessionStorage.getItem('token');
    return token;
}