import React from 'react';

class SideBarSearch extends React.Component {
  render() {
    return  (
        <div className="input-search-bar">
            <input type="text" name="search" placeholder="Pesquisar" className="form-control" />
        </div>
    );
  }
}

export default SideBarSearch;
