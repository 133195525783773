import React from 'react';
import ExecBar from "./ExecBar";

class ProgressExec extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="container-status-exec">
                <div className="status-exec">
                    <div className="status-exec-info">
                        <label>Status:</label>
                        <span>{this.props.info.status}</span>
                    </div>
                    <div className="status-exec-info">
                        <label>Time running:</label>
                        <span>{this.props.info.time_running}</span>
                    </div>
                    <div className="status-exec-info">
                        <label>Data transfer:</label>
                        <span>{this.props.info.transfer_data}</span>
                    </div>
                </div>

                <div className="status-exec-progress">
                    <div className="exec-progress-info">
                        <div className="status-exec-info-progress">
                            <label>Processed:</label>
                            <span>{this.props.total_finished + this.props.total_logs}</span>
                        </div>

                        <div className="status-exec-info-progress">
                            <span>{this.props.percentage_process}%</span>
                        </div>

                        <div className="status-exec-info-progress-end">
                            <label>To Process:</label>
                            <span>{this.props.total_to_process}</span>
                        </div>
                    </div>
                    <ExecBar width_data={this.props.percentage_data} width_log={this.props.percentage_log}/>
                </div>

            </div>
        );
    }
}

export default ProgressExec;

