import React from 'react';
import {Switch, Route} from "react-router-dom"
import Dashboard from "../Main/Dashboard";
import DashBot from "../Main/DashBot";
import DashExec from "../Main/Exec/DashExec";
import Settings from "../Main/Settings";
import Schedule from "../Main/sched/Schedule";

const Routes = () => {
    return (
        <Switch>
            <Route path="/dashboard" component={Dashboard} exact/>
            <Route path="/dashboard/bot/:id" component={DashBot}/>
            <Route path="/dashboard/exec/:bot/:id" component={DashExec}/>
            <Route path="/dashboard/settings" component={Settings}/>
            <Route path="/dashboard/schedule/:id" component={Schedule}/>
        </Switch>
    );
}

export default Routes;