import React from 'react';
import SideBarBots from "./SideBarBots";
import {Link} from "react-router-dom";

class SideBarMenu extends React.Component {
  render() {
    return  (
        <ul className="slide-out">
            <li>
                <Link to={"/dashboard"}><i className="material-icons">dashboard</i><span>Dashboard</span></Link>
            </li>
            <li>
                <Link to={"/dashboard/settings"}><i className="material-icons">settings</i><span>Settings</span></Link>
            </li>
            <li className="father-scrollable-li">
                <i className='fas fa-robot'></i><span>Data collectors</span>
            </li>
            <SideBarBots/>
        </ul>
    );
  }
}

export default SideBarMenu;
