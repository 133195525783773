import React from 'react';
import axios from "axios";
import { Redirect } from "react-router-dom";
import {isAuth} from "../Auth/Auth";


const api = axios.create({
    baseURL: 'https://station.datallog.com/user',
    headers: {'Content-Type': 'application/json'}
})

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {show: false, email: "", password: "", showEmailError: false, showPasswordError: false,
            emailOk: false,  passwordOk: false}

        this.handleButton = this.handleButton.bind(this);
        this.handleInputEmail = this.handleInputEmail.bind(this);
        this.focusOutEmail = this.focusOutEmail.bind(this);
        this.handleInputPassword = this.handleInputPassword.bind(this);
        this.focusOutPassword = this.focusOutPassword.bind(this);
    }

    // Function to call list of bots
    getToken = async () => {
        // Build url
        const url = '/login'
        const body = {email: this.state.email, password: this.state.password}

        // Put response in local data
        let data = false;
        data = await api.post(url, body).then(({data}) => data).catch(res => {this.setState({show: true});})

        if (data) {
            sessionStorage.setItem('token', data.token);
            this.setState({show: false});
        }

    }

    handleButton(e) {
        e.preventDefault();
        this.getToken();
    }

    handleInputEmail(e) {
        e.preventDefault();
        const new_input = e.target.value;
        if (this.validateEmail(new_input)) {
            this.setState({email: new_input, showEmailError: false, emailOk: true});
        } else {
            this.setState({email: new_input});
        }
    }

    focusOutEmail(e) {
        e.preventDefault();
        const new_input = e.target.value;
        if (!this.validateEmail(new_input)) {
            this.setState({showEmailError: true, emailOk: false});
        }

    }

    handleInputPassword(e) {
        e.preventDefault();
        const new_input = e.target.value;
        if (!this.checkEmpty(new_input)) {
            this.setState({password: new_input, showPasswordError: false, passwordOk: true});
        } else {
            this.setState({password: new_input, showPasswordError: true, passwordOk: false});
        }
    }

    focusOutPassword(e) {
        e.preventDefault();
        const new_input = e.target.value;
        if (this.checkEmpty(new_input)) {
            this.setState({showPasswordError: true, passwordOk: false});
        }
    }

    checkEmpty(str) {
        return (str == null || str == "");
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }


    render() {
        if (isAuth()) {
            return <Redirect to="/dashboard" />
        }

        let showHideClassName;
        if  (this.state.show) {
            showHideClassName =  "alert alert-danger display-error"
        } else {
            showHideClassName =  "alert alert-danger display-ok"
        }

        let showHideEmail;
        if  (this.state.showEmailError) {
            showHideEmail =  "ca-input-error-messages display-error"
        } else {
            showHideEmail =  "ca-input-error-messages display-ok"
        }

        let showHidePassword;
        if  (this.state.showPasswordError) {
            showHidePassword =  "ca-input-error-messages display-error"
        } else {
            showHidePassword =  "ca-input-error-messages display-ok"
        }

        let stateButton;
        if (this.state.emailOk && this.state.passwordOk) {
            stateButton = false;
        } else {
            stateButton = true;
        }

        return (<div className="login-page">
            <div className="login-page-row">
                <div className="login-page-row-content-side">
                    <div className="side-content">
                        <div className="main-logo">
                            <div className="logo-container">
                                <span className="logo">Datallog</span>
                                <label className="slogan">Less Work. More Action.</label>
                            </div>
                        </div>
                        <div className="main-form">
                            <div id="container-login">
                                <div className="ca-row">
                                    <div className="ca-col-12"><h2 className="ca-heading--subtitle">
                                        Informe seus dados abaixo
                                    </h2></div>
                                </div>
                                <div className={showHideClassName} role="alert">
                                    Wrong email or credential!
                                </div>
                                <div className="ca-row">
                                    <div className="ca-col-12">
                                        <div>
                                            <form className="ca-form">
                                                <div className="ca-form-content">
                                                    <div className="ca-row">
                                                        <div className="ca-col-12">
                                                            <div className="ca-field ca-form-group">
                                                                <label className="ca-label">
                                                                    <div>
                                                                        E-mail
                                                                        <span className="ca-label-required-mark"></span>
                                                                    </div>
                                                                </label>
                                                                <input
                                                                       className="ca-input ca-form-control"
                                                                       required="required"
                                                                       type="email" value={this.state.email}  onChange={this.handleInputEmail} onBlur={this.focusOutEmail}/>
                                                                    <div
                                                                         className={showHideEmail}><span
                                                                        className="ca-u-font--error">Informe um e-mail válido</span>
                                                                    </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ca-row">
                                                        <div className="ca-col-12">
                                                            <div className="ca-field ca-form-group">
                                                                <label className="ca-label">
                                                                    <div>
                                                                        Senha
                                                                        <span className="ca-label-required-mark"></span>
                                                                    </div>
                                                                </label>
                                                                <input
                                                                       className="ca-input ca-form-control"
                                                                       required="required"
                                                                       type="password" value={this.state.password}    onChange={this.handleInputPassword} onBlur={this.focusOutPassword}/>
                                                                    <div
                                                                         className={showHidePassword}><span
                                                                        className="ca-u-font--error">Campo Obrigatorio</span>
                                                                    </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ca-row">
                                                        <div className="ca-col-12">
                                                            <div
                                                                className="login__forgot-password-link ca-u-margin-bottom">
                                                                <span className="ca-link">
                                                                    <a href="https://www.datallog.com" target="_blank">
                                                                        Esqueci minha senha
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ca-row">
                                                        <div className="ca-col-12">
                                                            <div className="ca-submit-button"
                                                                 data-original-title="Para continuar, preencha corretamente os campos pendentes.">
                                                            <span className="ca-request-button">
                                                                <button id="btn-login"
                                                                        className="ca-btn ca-btn-primary ca-u-full-width"
                                                                         disabled={stateButton} onClick={this.handleButton}>
                                                                    <span className="ca-request-button__content">
                                                                        Entrar
                                                                      </span>
                                                                </button>
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ca-row">
                                                        <div className="ca-col-12">
                                                            <div
                                                                className="ca-u-min-margin-top ca-u-margin-bottom ca-u-text-center">
                                                            <span className="ca-link">
                                                                <a href="https://www.datallog.com" target="_blank">
                                                                    Não tem uma conta? Saiba mais!
                                                                </a>
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="main-programer">
                            <div className="ca-u-margin-top">
                                <span>É um programador? </span>
                                <span className="ca-link"><a href="https://www.datallog.com" target="_blank">
                            Conheça nosso SDK e plataforma serveless
                        </a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Login;