import React from 'react';
import RowSched from "./RowSched";
import axios from "axios";
import {getUrlToken} from "../Auth/Auth";
import DeleteScheduleDash from "../Modals/DeleteScheduleDash";


const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class DashBotSched extends React.Component {

    constructor(props) {
        super(props);
        this.state = {rows: [], offset: 0, limit: 10, last_page: false, loading: true, show_modal: false,
            action_delete_item_in_context:0}
        this._isMounted = false;
    }

    // Function to call list of schedules
    getScheds = async () => {
        // Build url
        const url = '/schedules/'+this.props.bot+'?offset='+this.state.offset+'&limit='+this.state.limit

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})

        if (data && this._isMounted) {
            // Pushing fetched data in bot array and update offset
            let newOffset = data.offset;
            if (newOffset) {
                this.setState({rows: [...this.state.rows, ...data.items], offset: newOffset, last_page: true, loading: false})
            } else {
                this.setState({rows: [...this.state.rows, ...data.items], offset: newOffset, loading: false})
            }
        }

    }

    deleteScheds = async () => {
        this.setState({ show_modal: false, loading: true, rows: [], offset: 0, limit: 10, last_page: false});
        const url = '/delete-schedule/'+this.props.bot+"/"+this.state.action_delete_item_in_context
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        await api.delete(url, config).then(res => {this.getScheds();}).catch(res => {this.getScheds();})
    }

    showModal = (id) => {
        this.setState({ show_modal: true, action_delete_item_in_context: id });
    };

    hideModal = () => {
        this.setState({ show_modal: false});
    };

    componentDidMount() {
        this._isMounted = true;
        this.getScheds();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if(prevProps.bot !== this.props.bot)
        {
            this.setState({rows: [], offset: 0, limit: 10, last_page: false, loading: true}, function () {
                this.getScheds();
            })
        }
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <div className="overlay-card-bot">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"></i><span
                    className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>;
        } else if (!this.state.rows.length) {
            content = <div className="overlay-card-bot-no-data"><i className="fas fa-robot no-data"/><span className="span-no-data">No data</span></div>;
        } else {
            content = this.state.rows.map(sched =><RowSched key={sched.startime} startime={sched.startime}
                                                            utc={sched.utc} day={sched.date_time} start={sched.start_in}
                                                            handleDelete={this.showModal}/>);
        }

        return (
            <div className="schedule-collector">
                <ul className="dt-tabs-list">
                    <li className="dt-tabs-title-collector is-active">
                        <span>Schedules</span>
                    </li>
                </ul>
                <div className="head-extractor">
                    <div className="head-column-schedule-collector">UTC</div>
                    <div className="head-column-schedule-collector">Start In</div>
                    <div className="head-column-schedule actions">Remove</div>
                </div>
                <DeleteScheduleDash show={this.state.show_modal} handleClose={this.hideModal} handleDelete={this.deleteScheds}/>
                <div className="content-extractor-schedule-collector">
                    {content}
                </div>
            </div>
        );
    }
}

export default DashBotSched;

