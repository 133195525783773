import React from 'react';
import axios from "axios";
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {access_key: "00000000000", secret_key: "**********", url_sdk: "**********************************"}
        this.getNewCredentials = this.getNewCredentials.bind(this);
        this.getNewUrl = this.getNewUrl.bind(this);
        this._isMounted = false;
    }

    // Function to call list of bots
    getKey = async () => {

        // Build url
        const url = '/config/key'

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {
            console.log(res);
        })
        if (data && this._isMounted) {
            // Pushing fetched data in state
            this.setState({access_key: data.access_key})
        }
    }


    getNewCredentials = async (e) => {

        e.preventDefault();
        // Build url
        const url = '/config/new-credentials'

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => { console.log(res);})
        if (data && this._isMounted) {
            // Pushing fetched data in state
            this.setState({ access_key: data.access_key, secret_key: data.secret_key  })
        }
    }

    getNewUrl = async (e) => {

        e.preventDefault();
        // Build url
        const url = '/sdk'

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => { console.log(res);})
        if (data && this._isMounted) {
            // Pushing fetched data in state
            this.setState({ url_sdk: data.url_pre_signed})
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getKey();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div id="settings">
                <div id="container-settings">
                    <div className="name-actions-collector">
                        <div id="title-settings">@datallog.com</div>
                        <div className="actions-schedule-collector">
                            <button className="btn-add">Save</button>
                        </div>
                    </div>
                    <div className="cont-settings-up">
                        <div className="cont-settings-up-inputs">
                            <div className="cont-settings-up-inputs-side">
                                <div className="cont-settings-up-inputs-mail">
                                    <div>
                                        <label>Email(login)</label>
                                        <div className="input-append">
                                            <input type="text" className="input-disable-mail" name="input-mail-disabled"
                                                   placeholder="joelder@arcaro.com.br"/>
                                            <span className="add-off iconed glyphicons calendar">
                                                <i className="far fa-envelope"/></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cont-settings-up-edit-mail">

                                    <div className="cont-settings-up-edit-mail-inputs">
                                        <div className="cont-settings-up-edit-mail-left">
                                            <label>New email</label>
                                            <div className="input-append">
                                                <input type="text" className="input-edit-mail"
                                                       name="input-mail-disabled"
                                                />
                                            </div>
                                        </div>
                                        <div className="cont-settings-up-edit-mail-right">
                                            <label>Confirm new email</label>
                                            <div className="input-append">
                                                <input type="text" className="input-edit-mail"
                                                       name="input-mail-disabled"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="cont-settings-up-inputs-side">
                                <div className="cont-settings-up-inputs-password">
                                    <div>
                                        <label>Password</label>
                                        <div className="input-append">
                                            <input type="text" className="input-disable-mail" name="input-mail-disabled"
                                                   placeholder="**********"/>
                                            <span className="add-off iconed glyphicons calendar">
                                                <i className="fas fa-lock" /></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="cont-settings-up-edit-password">
                                    <div className="cont-settings-up-edit-mail-inputs">
                                        <div className="cont-settings-up-edit-mail-left">
                                            <label>New password</label>
                                            <div className="input-append">
                                                <input type="text" className="input-edit-mail"
                                                       name="input-mail-disabled"
                                                />
                                            </div>
                                        </div>

                                        <div className="cont-settings-up-edit-mail-right">
                                            <label>Confirm new password</label>
                                            <div className="input-append">
                                                <input type="text" className="input-edit-mail"
                                                       name="input-mail-disabled"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cont-settings-up-inputs">
                            <div className="cont-settings-up-inputs-side">
                                <div className="cont-settings-up-inputs-mail">
                                    <div className="cont-settings-up-credentials">
                                        <div id="access-key">
                                            <label>Access Key:</label>
                                            <span>{this.state.access_key}</span>
                                        </div>
                                        <div id="secret-key">
                                            <label>Credentials Key:</label>
                                            <span>{this.state.secret_key}</span>
                                        </div>
                                        <div className="div-button-new-credentials">
                                            <button onClick={this.getNewCredentials} className="btn-new-credentials">New Credentials</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cont-settings-up-inputs-side">
                                <div className="cont-settings-up-inputs-mail">
                                    <div className="cont-settings-up-credentials-url">
                                        <div className="div-url-sdk">
                                            <label>URL SDK:</label>
                                            <span>{this.state.url_sdk}</span>
                                        </div>
                                        <div className="div-button-new-credentials">
                                            <button onClick={this.getNewUrl} className="btn-new-credentials">Get URL</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cont-settings-down">
                        <div className="cont-settings-down-info">
                            <h5>Para alterar seus dados confirme a senha atual.</h5>
                            <label>Senha atual</label>
                            <div className="input-append">
                                <input id="input-password" type="text" className="input-edit-mail"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;
