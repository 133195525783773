import React from 'react';
import axios from "axios";
import RowExec from "./RowExec";
import {getUrlToken} from "../Auth/Auth";

const api = axios.create({
    baseURL: 'https://station.datallog.com/api/execs',
    headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}
})

class DashBotExec extends React.Component {

    constructor(props) {
        super(props);
        this.state = {rows: [], page: 0, limit: 10, last_page: false, loading: true}
        this._isMounted = false;
    }

    // Function to call list of bots
    getExecs = async () => {
        // Build url
        const url = '/info-status/'+this.props.bot+'?page='+this.state.page+'&limit='+this.state.limit

        // Put response in local data
        let data = false;
        let config = {headers: {'Content-Type': 'application/json', 'Authorization': getUrlToken()}}
        data = await api.get(url, config).then(({data}) => data).catch(res => {console.log(res);})

        if (data && this._isMounted) {
            // Pushing fetched data in bot array and update offset
            let newPage = this.state.page + 1;
            if ((newPage*this.state.limit) >= data.total_itens) {
                if (this._isMounted) {
                    this.setState({rows: [...this.state.rows, ...data.data], page: newPage, last_page: true, loading: false});
                }
            } else {
                if (this._isMounted) {
                    this.setState({rows: [...this.state.rows, ...data.data], page: newPage, loading: false});
                }
            }
        }

    }
    componentDidMount() {
        this._isMounted = true;
        this.getExecs();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if(prevProps.bot !== this.props.bot)
        {
            this.setState({rows: [], page: 0, limit: 10, last_page: false, loading: true}, function (){
                this.getExecs();
            });
        }
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <div className="overlay-card-bot">
                <div className="overlay-card-bot-loading">
                    <i className="fas fa-robot loading"></i><span
                    className="span-loading wait-loading">Loading <span>.</span><span>.</span><span>.</span></span>
                </div>
            </div>;
        } else if (!this.state.rows.length) {
            content = <div className="overlay-card-bot-no-data"><i className="fas fa-robot no-data"/><span className="span-no-data">No data</span></div>;
        } else {
            content = this.state.rows.map(exec =><RowExec key={exec._id}  bot_id={this.props.bot}  exec_id={exec._id} info = {exec.info} status = {exec.status} />);
        }

        return (
            <div className="exec-collector">
                <ul className="dt-tabs-list">
                    <li className="dt-tabs-title-collector is-active">
                        <span>Executions</span>
                    </li>
                </ul>
                <div className="head-extractor-collector">
                    <div className="head-column-exec-collector">Id</div>
                    <div className="head-column-exec-collector">Status</div>
                    <div className="head-column-exec log">Log</div>
                </div>
                <div className="content-extractor-exec-collector">
                    {content}
                </div>
            </div>
        );
    }
}

export default DashBotExec;

