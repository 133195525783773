import React from 'react';

const RowSched = (props) => {
    return (
        <div className="row-content-extractor-schedule-collector">
            <div className="row-column-content-extractor-schedule-collector">{props.utc}</div>
            <div className="row-column-content-extractor-schedule-collector">{props.start}</div>
            <div className="row-column-content-extractor-schedule-collector actions">
                <a href="#" onClick={()=>props.handleDelete(props.startime)}><i className='far fa-trash-alt'/></a>
            </div>
        </div>
    );
}

export default RowSched;
